var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"}),_c('div',{staticClass:"column"},[_vm._m(1),_c('button',{staticClass:"button is-fullwidth",class:{ 'is-link': _vm.highlightCurrentlyOpened },on:{"click":_vm.highlight}},[_vm._v(" Podświetl aktualnie otwarte ")]),_c('table',{staticClass:"table is-hoverable is-stripped is-fullwidth margin-top-1rem"},[_c('thead',[_c('tr',[_c('th',[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v("Giełda")]),_c('span',{staticClass:"icon has-text-grey-light"},[_c('font-awesome-icon',{staticClass:"clickable-icon",class:{ 'has-text-link': _vm.sortingOrder == 'asc' && _vm.sorting == 'name' },attrs:{"icon":"arrow-down"},on:{"click":function($event){return _vm.setSorting('name', 'asc')}}})],1),_c('span',{staticClass:"icon has-text-grey-light"},[_c('font-awesome-icon',{staticClass:"clickable-icon",class:{ 'has-text-link': _vm.sortingOrder == 'desc' && _vm.sorting == 'name' },attrs:{"icon":"arrow-up"},on:{"click":function($event){return _vm.setSorting('name', 'desc')}}})],1)])]),_c('th',[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v("Kraj")]),_c('span',{staticClass:"icon has-text-grey-light"},[_c('font-awesome-icon',{staticClass:"clickable-icon",class:{ 'has-text-link': _vm.sortingOrder == 'asc' && _vm.sorting == 'country' },attrs:{"icon":"arrow-down"},on:{"click":function($event){return _vm.setSorting('country', 'asc')}}})],1),_c('span',{staticClass:"icon has-text-grey-light"},[_c('font-awesome-icon',{staticClass:"clickable-icon",class:{ 'has-text-link': _vm.sortingOrder == 'desc' && _vm.sorting == 'country' },attrs:{"icon":"arrow-up"},on:{"click":function($event){return _vm.setSorting('country', 'desc')}}})],1)])]),_c('th',[_vm._v("Godziny otwarcia")])])]),_c('tbody',_vm._l((_vm.exchanges),function(exchange){return _c('tr',{key:exchange.name},[_c('td',{class:{
              'is-selected':
                _vm.highlightCurrentlyOpened && exchange.currentlyOpened,
              'is-info': _vm.$route.query.show == exchange.name,
            }},[_vm._v(" "+_vm._s(exchange.name)+" ")]),_c('td',{class:{
              'is-selected':
                _vm.highlightCurrentlyOpened && exchange.currentlyOpened,
            }},[_c('span',{staticClass:"flag-icon",class:'flag-icon-' + exchange.flag}),_vm._v(" "+_vm._s(exchange.country)+" ")]),_c('td',{class:{
              'is-selected':
                _vm.highlightCurrentlyOpened && exchange.currentlyOpened,
            }},[_vm._v(" "+_vm._s(exchange.open)+" - "+_vm._s(exchange.close)+" ")])])}),0)]),_c('div',[_vm._m(2),_c('div',{staticClass:"columns"},[_c('RankingWidget',{staticClass:"column",attrs:{"ranking":_vm.stocksRanking}})],1)])]),_c('div',{staticClass:"column is-3"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"}),_c('div',{staticClass:"column has-text-centered"},[_c('h1',{staticClass:"title has-fancy-underline"},[_vm._v("Godziny otwarcia giełd")])]),_c('div',{staticClass:"column is-3"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has-text-justified"},[_vm._v(" Giełdy są jednym z najważniejszych miejsc handlowych, gdzie inwestorzy mogą kupować i sprzedawać akcje, obligacje, surowce i inne instrumenty finansowe. Każda giełda ma swoje unikalne godziny otwarcia, które zależą od strefy czasowej, w której się znajduje. Warto wiedzieć, że giełdy nie są czynne przez całą dobę i mają określone dni wolne, w których nie odbywa się handel. W poniższej liście przedstawiamy godziny otwarcia najważniejszych giełd świata na których handluje się akcjami. "),_c('strong',[_vm._v("Wszystkie godziny podane są w czasie polskim.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column content is-full-width has-text-centered"},[_c('h3',{staticClass:"has-fancy-underline"},[_vm._v(" A teraz zobacz gdzie możesz handlować akcjami ")])])])}]

export { render, staticRenderFns }