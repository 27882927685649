<template>
  <div>
    <div class="columns is-vcentered is-gapless">
      <div class="column is-3"></div>
      <div class="column">
        <h1 class="title has-fancy-underline has-text-centered">
          Szczegółowe informacje o {{ broker.name }}
        </h1>
        <div class="content has-text-justified padding-1rem">
          <component :is="brokerDetailLoader" :key="$route.params.slug">
            <template #smallCompare="smallCompareProps">
              <side-to-side-compare-columns :brokers="smallCompareProps.brokers" :isAllFields="false"
                :fields="smallCompareProps.fields"></side-to-side-compare-columns>
            </template>
            <template #openAccount="openAccountProps">
              <BrokerListElement :broker="loadBroker(openAccountProps.slug)" :index="0"
                :fields="openAccountProps.fields || fields" :linkSuffix="openAccountProps.linkSuffix" />
            </template>
          </component>
        </div>
        <article class="message is-info" v-show="isMessageVisible">
          <div class="message-header">
            <p>Tylko w JakiBroker.pl</p>
            <button class="delete" aria-label="delete" @click="isMessageVisible = !isMessageVisible"></button>
          </div>
          <div class="message-body">
            Poniżej znajdziesz szczegółowe informacje na temat brokera
            {{ broker.name }}. Elementy
            <span class="has-fancy-underline has-text-weight-bold">pogrubione i podkreślone na niebiesko</span>
            zaznaczają najważniejsze dla ciebie cechy, które zaznaczyłeś w
            filtrach w
            <a @click="goToBrokerList">Porównywarce Brokerów</a>.
          </div>
        </article>
        <side-to-side-compare-columns :brokers="[broker.slug]"></side-to-side-compare-columns>
      </div>
      <div class="column is-3"></div>
    </div>
    <div class="columns">
      <div class="column is-2"></div>
      <div class="column">
        <div class="columns">
          <div class="column content is-full-width has-text-centered">
            <h3 class="has-fancy-underline">
              Zobacz jak {{ broker.name }} wypada w porównaniu:
            </h3>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <compare-widget :broker1="{
            name: broker.name,
            slug: broker.slug,
            imageSrc: broker.imageSrc,
          }" :broker2="{
            name: otherBrokerToCompare1.name,
            slug: otherBrokerToCompare1.slug,
            imageSrc: otherBrokerToCompare1.imageSrc,
          }"></compare-widget>
          </div>
          <div class="column is-half">
            <compare-widget :broker1="{
            name: broker.name,
            slug: broker.slug,
            imageSrc: broker.imageSrc,
          }" :broker2="{
            name: otherBrokerToCompare2.name,
            slug: otherBrokerToCompare2.slug,
            imageSrc: otherBrokerToCompare2.imageSrc,
          }"></compare-widget>
          </div>
        </div>
      </div>
      <div class="column is-2"></div>
    </div>
  </div>
</template>

<script>
import SideToSideCompareColumns from "./SideToSideCompareColumns";
import database from "../database.json";
import CompareWidget from "./CompareWidget.vue";
import BrokerListElement from "./BrokerListElement";

export default {
  name: "BrokerDetail",
  data() {
    return {
      broker: null,
      top3: ["etoro", "xtb", "plus500"],
      isMessageVisible: true,
      fields: {
        description: false,
        rate: true,
        minInstallment: false,
        currencies: false,
        countryOfOrigin: false,
        investmentProtection: false,
        platforms: false,
        countryOfRegulation: false,
        instruments: true,
        spread: false,
        commission: false,
        fundingMethods: true,
        leverage: true,
        ecn: false,
      },
    };
  },
  computed: {
    metaTitle() {
      return this.broker.name;
    },
    metaDescription() {
      return this.broker.description;
    },
    metaImageSrc() {
      return this.broker.imageSrc;
    },
    metaUrlContent() {
      return `https://jakibroker.pl/brokerzy/${this.broker.slug}/`;
    },
    otherBrokersToCompareSlugs() {
      const filtered_brokers = this.top3.filter(
        (slug) => slug != this.broker.slug
      );
      return { slug1: filtered_brokers[0], slug2: filtered_brokers[1] };
    },
    otherBrokerToCompare1() {
      const { slug1 } = this.otherBrokersToCompareSlugs;
      return this.$store.getters.databaseBrokers.find(
        (broker) => broker.slug == slug1
      );
    },
    otherBrokerToCompare2() {
      const { slug2 } = this.otherBrokersToCompareSlugs;
      return this.$store.getters.databaseBrokers.find(
        (broker) => broker.slug == slug2
      );
    },
    brokerDetailLoader() {
      // it's here just to invoke reactivity
      const slug = this.$route.params.slug;
      return () => import(`@/brokers/${slug}.md`);
    },
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },

        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        { property: "og:image", content: this.metaImageSrc },
        { property: "og:image:url", content: this.metaImageSrc },
        { property: "og:image:width", content: 1920 },
        { property: "og:image:height", content: 1080 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "JakiBroker.pl - %s",
    };
  },
  created() {
    this.broker = database.find((item) => item.slug == this.$route.params.slug);
    this.$store.commit("setCurrentPageTitle", this.broker.name);
  },
  jsonld() {
    const broker = this.broker;
    return {
      "@context": "http://schema.org",
      "@type": "FinancialService",
      "name": broker.name,
      "address": {
        "@type": "PostalAddress",
        "addressRegion": broker.countryOfOrigin
      },
      "logo": this.getFullUrlBrokerImage(broker),
      "description": broker.description,
      "serviceType": ["Investment", "Trading"],
      // "aggregateRating": {
      //   "@type": "AggregateRating",
      //   "ratingValue": broker.rate.toString()
      // },
      "offers": {
        "@type": "Offer",
        "priceCurrency": broker.currencies.join(', '),
        "price": broker.minInstallment.replace(/[^0-9.]/g, '') // Removes non-numeric characters for the price
      },
      "areaServed": broker.countryOfRegulation.map(country => ({
        "@type": "Country",
        "name": country
      })),
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Trading Instruments",
        "itemListElement": broker.instruments.map(instrument => ({
          "@type": "OfferCatalog",
          "name": instrument
        }))
      },
      "feesAndCommissionsSpecification": broker.commission,
      "isRelatedTo": {
        "@type": "Product",
        "name": broker.platforms.join(', ')
      },
      "disambiguatingDescription": broker.disclaimer
    };
  },
  components: {
    SideToSideCompareColumns,
    CompareWidget,
    BrokerListElement
  },
};
</script>
