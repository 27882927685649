<template>
  <div class="landing-font">
    <section class="section">
      <div class="columns">
        <div class="column is-3"></div>
        <div class="column">
          <h2 class="
              title
              has-text-centered has-fancy-underline
              padding-bottom-1rem
            ">
            <span>Brokerzy TOP 3</span>
          </h2>
          <div>
            <broker-list-element v-for="(broker, index) in topBrokers" :key="broker.name" :broker="broker"
              :index="index" :fields="fields" :landing="true">
            </broker-list-element>
            <div class="has-text-centered">
              <router-link :to="{ name: 'brokerlist' }" v-if="isMobile()">
                <span class="is-link">Zobacz ranking najlepszych brokerów</span>
              </router-link>
              <router-link :to="{ name: 'brokerlist' }" v-else class="padding-bottom-2rem">
                <button class="
                    button
                    is-large is-info is-rounded
                    cta-button
                    is-outlined
                  " @click="scrollToForm">
                  <span>ZOBACZ RANKING NAJLEPSZYCH BROKERÓW</span>
                  <span class="icon">
                    <font-awesome-icon class="
                        clickable-icon
                        has-text-link
                        animated
                        tada
                        infinite
                      " icon="arrow-circle-right"></font-awesome-icon>
                  </span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="column is-3"></div>
      </div>
    </section>

    <section class="section has-background-white-ter">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <h2 class="
              title
              has-text-centered has-fancy-underline
              padding-bottom-1rem
            ">
            <span>Zacznij inwestować już teraz</span>
          </h2>
          <h2 class="subtitle has-text-centered">
            JakiBroker.pl to najszybszy sposób na wybór właściwego brokera FOREX
            i CFD.
          </h2>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>


    <section class="section landing-section-margin-bottom has-background-white-ter">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <div class="columns">
            <div class="column has-text-centered-desktop">
              <span class="icon has-text-success">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
              </span>
              <span>&nbsp;Porównywarka brokerów</span>
            </div>
            <div class="column has-text-centered-desktop">
              <span class="icon has-text-success">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
              </span>
              <span>&nbsp;Tylko kontrolowani brokerzy</span>
            </div>
            <div class="column has-text-centered-desktop">
              <span class="icon has-text-success">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
              </span>
              <span>&nbsp;100% Darmowe narzędzie</span>
            </div>
          </div>
          <div class="columns">
            <RankingWidget class="column is-half" :ranking="suggestedRanking1"></RankingWidget>
            <RankingWidget class="column is-half" :ranking="suggestedRanking2"></RankingWidget>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>

    <section class="section landing-section-margin-bottom">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <div class="columns">
            <div class="column">
              <h2 class="title has-fancy-underline has-text-centered">
                <span>Wybór brokera nigdy nie był łatwiejszy</span>
              </h2>
              <div class="content has-text-justified">
                <p>
                  Zebraliśmy i uporządkowaliśmy oferty brokerów, aby dopasować
                  je do Twoich indywidualnych potrzeb i oczekiwań, jednocześnie
                  unikając zbędnych informacji oraz skomplikowanej terminologii.
                </p>

                <p>
                  <strong>Nasz specjalny system umożliwia szybkie i efektywne
                    znalezienie najlepszego brokera, niezależnie od tego, czy
                    jesteś początkującym inwestorem, czy doświadczonym
                    traderem.</strong>
                </p>
                <p>
                  Zwróć uwagę na niebieski znak zapytania
                  <span @click="showInfoModalWith('exampleQuestionMark')">
                    <font-awesome-icon class="
                        clickable-icon
                        has-text-link
                        animated
                        tada
                        infinite
                      " icon="question"></font-awesome-icon>
                  </span>
                  który w prosty sposób wyjaśni Ci skomplikowane kwestie
                  inwestycyjne w kilku zdaniach.
                </p>
              </div>
            </div>
            <div class="column has-text-centered">
              <lord-icon class="big-icon" animation="auto" params="70" palette="#121331;#3273dc"
                src="/lordicon/21-avatar-outline.json"></lord-icon>
              <div class="has-text-centered">
                <p>Dołącz do <strong class="has-fancy-underline">2461 osób</strong>, które znalazły brokera przez
                  JakiBroker.pl</p>
                <p class="padding-bottom-1rem"><small>(dane z ostatnich 6 miesięcy)</small></p>
                <button class="button is-large is-info is-rounded cta-button" @click="scrollToForm">
                  <span class="icon">
                    <lord-icon animation="hover" target="button" class="big-icon" params="100" palette="#ffffff;#ffffff"
                      src="/lordicon/312-avatar-icon-calm-search-outline.json"></lord-icon>
                  </span>
                  <span>WYBIERZ BROKERA</span>
                </button>
              </div>

            </div>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>

    <section class="section landing-section-margin-bottom has-background-white-ter">
      <div class="columns">
        <div class="column is-4"></div>
        <div class="column">
          <lord-icon animation="hover" src="/lordicon/41-quotation-mark-second-outline.json"></lord-icon>
          <h2 class="subtitle has-text-centered is-italic">
            Znalezienie brokera zajęło mi mniej niż 3 minuty. Dosłownie
            najszybszy sposób na porównanie ofert brokerów FOREX i CFD.
          </h2>
          <span class="is-pulled-right">~Trader37</span>
        </div>
        <div class="column is-4"></div>
      </div>
    </section>

    <section class="section landing-section-margin-bottom">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <div class="columns">
            <div class="column">
              <div class="columns is-multiline options-padding">
                <div class="column is-half">
                  <p>
                    <lord-icon class="small-icon" animation="hover" target="p" params="100" palette="#121331;#3273dc"
                      src="/lordicon/454-calculator-outline.json"></lord-icon>
                    <span>prowizje, opłaty</span>
                  </p>
                </div>
                <div class="column is-half">
                  <p>
                    <lord-icon class="small-icon" animation="hover" target="p" params="100" palette="#121331;#3273dc"
                      src="/lordicon/402-legal-balance-legal-outline.json"></lord-icon>
                    <span>regulacje</span>
                  </p>
                </div>

                <div class="column is-half">
                  <p>
                    <lord-icon class="small-icon" animation="hover" target="p" params="100" palette="#121331;#3273dc"
                      src="/lordicon/164-graph-candles-stock-outline.json"></lord-icon>
                    <span>dostępne rynki</span>
                  </p>
                </div>
                <div class="column is-half">
                  <p>
                    <lord-icon class="small-icon" animation="hover" target="p" params="100" palette="#121331;#3273dc"
                      src="/lordicon/139-basket-outline.json"></lord-icon>
                    <span>funkcjonalność</span>
                  </p>
                </div>
                <div class="column is-half">
                  <p>
                    <lord-icon class="small-icon" animation="hover" target="p" params="100" palette="#121331;#3273dc"
                      src="/lordicon/285-avatar-female-approved-outline.json"></lord-icon>
                    <span>wsparcie</span>
                  </p>
                </div>
                <div class="column is-half">
                  <p>
                    <lord-icon class="small-icon" animation="hover" target="p" params="100" palette="#121331;#3273dc"
                      src="/lordicon/26-share-network-outline.json"></lord-icon>
                    <span>ECN</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="column">
              <h2 class="
                  title
                  has-fancy-underline has-text-centered
                  padding-bottom-1rem
                ">
                Sam wybierz co chcesz widzieć
              </h2>
              <div class="content has-text-justified">
                <p>
                  Dzięki opcji dostosowania widoczności informacji, jako
                  początkujący inwestor nie zostaniesz przytłoczony
                  niepotrzebnymi danymi, które nie są Ci potrzebne do
                  rozpoczęcia przygody z inwestowaniem.
                </p>
                <p>
                  Jako zaawansowany uczestnik rynku, zobaczysz jedynie
                  informacje, które są aktualnie istotne dla przeprowadzenia
                  transakcji lub znalezienia brokera z najniższym spreadem na
                  Twoim ulubionym rynku.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>
    <section class="section landing-section-margin-bottom">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <div class="columns">
            <div class="column">
              <h2 class="
                  title
                  has-fancy-underline has-text-centered
                  padding-bottom-1rem
                ">
                <span>Wszystko, co potrzebne do rozpoczęcia inwestycji</span>
              </h2>
              <div class="content has-text-justified">
                <p>
                  Wybór brokera, określenie instrumentów finansowych, ustalenie
                  budżetu oraz wybór platformy inwestycyjnej - to zadania, które
                  dla początkującego inwestora mogą wydawać się skomplikowane.
                  Dlatego JakiBroker.pl oferuje Ci wszystko bezpłatnie i w
                  jednym miejscu, abyś mógł łatwo rozpocząć swoją przygodę z
                  inwestowaniem.
                </p>
              </div>
              <div class="has-text-centered">
                <div class="columns">
                  <div class="column">
                    <router-link class="button is-large is-info is-rounded cta-button" :to="{
              name: 'article',
              params: { slug: 'jak-wybrac-brokera' },
            }">
                      <span v-if="isMobile">Jak wybrać brokera?</span>
                      <span v-else>Przeczytaj jak wybrać brokera</span>
                      <span class="icon">
                        <font-awesome-icon icon="arrow-circle-right"></font-awesome-icon>
                      </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="column has-text-centered">
              <lord-icon class="big-icon" animation="auto" params="70" palette="#121331;#3273dc"
                src="/lordicon/406-study-graduation-outline.json"></lord-icon>
            </div>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>

    <section class="section landing-section-margin-bottom has-background-white-ter">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <h2 class="
              title
              has-fancy-underline has-text-centered
              padding-bottom-1rem
            ">
            Porównaj interesujących brokerów
          </h2>
        </div>
        <div class="column is-2"></div>
      </div>
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <div class="columns is-multiline">
            <div class="column">
              <compare-widget :broker1="{
              name: 'Saxo Bank',
              slug: 'saxo',
              imageSrc: '/img/logos/logo-saxo.png',
            }" :broker2="{
              name: 'XTB',
              slug: 'xtb',
              imageSrc: '/img/logos/logo-xtb.png',
            }"></compare-widget>
            </div>
            <div class="column">
              <compare-widget :broker1="{
              name: 'eToro',
              slug: 'etoro',
              imageSrc: '/img/logos/logo-etoro.png',
            }" :broker2="{
              name: 'plus500',
              slug: 'plus500',
              imageSrc: '/img/logos/logo-plus500.png',
            }"></compare-widget>
            </div>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>

    <section class="section landing-section-margin-bottom">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <h2 class="
              title
              has-fancy-underline has-text-centered
              padding-bottom-1rem
            ">
            Najnowsze artukuły
          </h2>
        </div>
        <div class="column is-2"></div>
      </div>
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column content">
          <div class="columns is-multiline">
            <div class="column is-half">
              <ArticleWidget :article="suggestedArticle1"></ArticleWidget>
            </div>
            <div class="column is-half">
              <ArticleWidget :article="suggestedArticle2"></ArticleWidget>
            </div>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>
  </div>
</template>

<script>
import articles from "@/articles.json";
import ArticleWidget from "./ArticleWidget";
import CompareWidget from "./CompareWidget";
import BrokerListElement from "./BrokerListElement.vue";
import RankingWidget from "./RankingWidget.vue";

export default {
  name: "Landing",
  data() {
    return {
      articles: articles.filter((elem) => elem.isPublic),
      fields: {
        description: false,
        rate: true,
        minInstallment: false,
        currencies: false,
        countryOfOrigin: false,
        investmentProtection: false,
        platforms: false,
        countryOfRegulation: false,
        instruments: true,
        spread: false,
        commission: false,
        fundingMethods: true,
        leverage: true,
        ecn: false,
      },
    };
  },
  methods: {
    scrollToForm() {
      document.querySelector("#form").scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    topBrokers() {
      let brokers = [...this.$store.getters.databaseBrokers];
      brokers.sort((a, b) => b.rate - a.rate);
      return brokers.slice(0, 3);
    },
    suggestedArticle1() {
      return this.articles[0];
    },
    suggestedArticle2() {
      return this.articles[2];
    },
    suggestedRanking1() {
      return this.$store.state.rankings[0];
    },
    suggestedRanking2() {
      return this.$store.state.rankings[1];
    },
  },
  components: {
    CompareWidget,
    ArticleWidget,
    BrokerListElement,
    RankingWidget,
  },
};
</script>

<style scoped>
.landing-font {
  font-size: 1.25rem;
  color: #858585;
}

.small-icon {
  width: 48px;
  height: 48px;
}

.big-icon {
  width: 256px;
  height: 256px;
}

.zero-padding-top {
  padding-top: 0;
}

.options-padding {}

@media only screen and (min-width: 769px) {
  .options-padding {
    padding-top: 4.5rem;
  }
}
</style>
