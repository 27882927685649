<template>
    <div class="column is-one-forth has-borders-column">
        <span class="heading-inline">ocena</span>
        <br />
        <small>
            <progress class="progress" :value="broker.rate" max="5" :class="{
                'is-danger': broker.rate < 2,
                'is-warning': broker.rate < 4 && broker.rate >= 2,
                'is-success': broker.rate >= 4,
            }"></progress>
            {{ broker.rate }}
        </small>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
    },
};

</script>