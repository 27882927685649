<template>
    <ul class="margin-bottom-1rem">
        <li v-for="fundingMethod in fundingMethods" :key="fundingMethod">
            <span v-if="fundingMethod == 'Apple Pay'" class="icon has-text-info">
                <font-awesome-icon :icon="['fab', 'apple-pay']"></font-awesome-icon>
            </span>
            <span v-else-if="fundingMethod == 'PayPal'" class="icon has-text-info">
                <font-awesome-icon :icon="['fab', 'paypal']"></font-awesome-icon>
            </span>
            <span v-else-if="fundingMethod == 'Przelew'" class="icon has-text-info">
                <font-awesome-icon icon="exchange-alt"></font-awesome-icon>
            </span>
            <span v-else-if="fundingMethod == 'Karta Płatnicza'" class="icon has-text-info">
                <font-awesome-icon icon="credit-card"></font-awesome-icon>
            </span>
            <span v-else class="icon has-text-info">
                <font-awesome-icon icon="chevron-right"></font-awesome-icon>
            </span>
            <span :class="{
                'has-text-weight-bold has-fancy-underline':
                    fundingMethod == selectedFundingMethod ||
                    fastOnlineWireTransfers[fundingMethod.toLowerCase()] !==
                    undefined,
            }">&nbsp;{{ fundingMethod }}</span>
        </li>
    </ul>
</template>

<script>
export default {
    name: "FundingMethodsList",
    props: ["fundingMethods"],
    computed: {
        fastOnlineWireTransfers() {
            return this.$store.state.fastOnlineWireTransfers;
        },
        selectedFundingMethod() {
            return this.$store.state.filter.fundingMethod;
        },
    }

}
</script>