export const sortingOptions = {
    suggested: 'suggested',
    lowestSpread: 'lowestSpread',
    lowestMinimumInstallment: 'lowestMinimumInstallment',
    biggestInterestRate: 'biggestInterestRate',
    highestLeverage: 'highestLeverage',
    alphabetically: 'alphabetically',
}

export const sortingLabels = {
    [sortingOptions.suggested]: 'Sugerowane',
    [sortingOptions.lowestSpread]: 'Najniższy spread',
    [sortingOptions.lowestMinimumInstallment]: 'Najniższa minimalna wpłata',
    [sortingOptions.highestLeverage]: 'Największa dźwignia',
    [sortingOptions.alphabetically]: 'Alfabetycznie',
    [sortingOptions.biggestInterestRate]: 'Najwyższe oprocentowanie wolnych środków',
}

